import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import MainLayout from './components/MainLayout';
import PostsPage from './Pages/PostsPage/PostsPage';
import About from './Pages/About';
import Contact from './Pages/Contact';
import FirstProject from './Pages/Projects/FirstProject';
import SecondProject from './Pages/Projects/SecondProject';
import ComingSoon from './Pages/comingSoon/ComingSoon';

function App() {
  return (
    <div className="App">
      <ComingSoon />
    </div>
  );
}

export default App;
