import React from 'react';
import './comingSoon.css';

function ComingSoon() {
  const imageInPublic = (imageName) => {
    return process.env.PUBLIC_URL + '/' + imageName;
  };
  const socialLinks = [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/noorawied/',
    },
    {
      name: 'Github',
      url: 'https://github.com/awiednoor',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/awiednoor',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/awiednoor/',
    },
    {
      name: 'Medium',
      url: 'https://medium.com/@awiednoor',
    },
  ];
  return (
    <div className="main-div">
      <img
        className="coming-soon-image"
        src={imageInPublic('coming-soon.png')}
        alt="Coming Soon"
      />
      <h1 className="coming-soon-title">Something Exciting Coming Soon</h1>
      <p className="social-links-title">Follow me on:</p>
      <div className="social-links">
        {socialLinks.map((socialLink) => (
          <a
            key={socialLink.name}
            className="social-link"
            href={socialLink.url}
            target="_blank"
          >
            <img
              src={imageInPublic(`${socialLink.name}.png`)}
              alt={socialLink.name}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default ComingSoon;
